<script setup lang="ts">
const { data: user } = useUser();
</script>

<template>
  <DefaultAvatar
    v-if="user"
    class="shrink-0 font-normal"
    :name="`${user.name} ${user.last_name || ''}`"
    size="large"
    shape="circle"
  />
</template>
