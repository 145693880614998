import { fetcher } from '../fetcher';

const NOTIFICATIONS_ENDPOINT = '/clinic/notifications';

export const notificationsService = {
  getNotifications: async () => {
    return await fetcher(`${NOTIFICATIONS_ENDPOINT}`, {
      method: 'GET',
      schema: notificationsSchema,
    });
  },
  getNotification: async (notification_id: number) => {
    return await fetcher(`${NOTIFICATIONS_ENDPOINT}/${notification_id}`, {
      method: 'GET',
    });
  },
  deleteNotification: async (notification_id: number) => {
    return await fetcher(`${NOTIFICATIONS_ENDPOINT}/${notification_id}`, {
      method: 'DELETE',
    });
  },
};
