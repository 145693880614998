import { fetcher } from '../fetcher';

const USER_ENDPOINT = '/users';

export const userService = {
  getAccount: async () => {
    return await fetcher(`${USER_ENDPOINT}/me`, {
      method: 'GET',
      schema: userSchema,
    });
  },
};
