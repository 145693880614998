import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { notificationsService } from '~/utils/api/notificationsService';
import type { Notification } from '~/utils/types';

export function useNotifications() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['notifications'],
    queryFn: notificationsService.getNotifications,
  });

  const unreadNotifications = computed(() => {
    if (!data.value) return [];

    return data.value.filter((notification) => !notification.is_read);
  });

  const { mutate: deleteNotification } = useMutation({
    mutationFn: (notification_id: number) =>
      notificationsService.deleteNotification(notification_id),
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania powiadomienia',
        life: 3000,
      });
    },
    onMutate: (notification_id: number) => {
      queryClient.setQueryData(['notifications'], (data: Notification[]) => {
        return data.filter(
          (notification) => notification.id !== notification_id,
        );
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  const { mutate: readNotification } = useMutation({
    mutationFn: (notification_id: number) =>
      notificationsService.getNotification(notification_id), // marks notification as read
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas odczytywania powiadomienia',
        life: 3000,
      });
    },
    onMutate: (notification_id: number) => {
      queryClient.setQueryData(['notifications'], (data: Notification[]) => {
        return data.map((notification) => {
          if (notification.id === notification_id) {
            return { ...notification, is_read: true };
          }
          return notification;
        });
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  function handleDeleteNotification(notification_id: number) {
    deleteNotification(notification_id);
  }

  function handleReadNotification(notification_id: number) {
    readNotification(notification_id);
  }

  return {
    data,
    unreadNotifications,
    handleDeleteNotification,
    handleReadNotification,
  };
}
