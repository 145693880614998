<script setup lang="ts">
const op = ref();

const { mutate: logout } = useLogout();
</script>

<template>
  <PrimeButton
    class="shrink-0 p-0"
    text
    severity="secondary"
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <UserAvatar />
    <i class="pi pi-chevron-down" />
  </PrimeButton>

  <PrimePopover class="min-w-44 before:!border-0 after:!border-0" ref="op">
    <PrimeButton
      class="w-full !justify-start !font-normal"
      text
      size="small"
      severity="secondary"
      @click="logout"
    >
      <i class="pi pi-sign-out" />
      Wyloguj
    </PrimeButton>
  </PrimePopover>
</template>
