<script setup lang="ts">
const MENU = [
  {
    icon: 'pi pi-calendar',
    title: 'Kalendarz',
    path: '/calendar/week',
  },
  {
    icon: 'pi pi-server',
    title: 'Schematy',
    path: '/schemats',
  },
  {
    icon: 'pi pi-heart',
    title: 'Badania kliniczne',
    path: '/surveys',
  },
  {
    icon: 'pi pi-users',
    title: 'Pacjenci',
    path: '/patients',
  },
  {
    icon: 'pi pi-check-square',
    title: 'Formularze',
    path: '/forms',
  },
  {
    icon: 'pi pi-bell',
    title: 'Powiadomienia',
    path: '/notifications',
  },
  {
    icon: 'pi pi-briefcase',
    title: 'Personel',
    path: '/employees',
  },
  {
    icon: 'pi pi-link',
    title: 'Leki',
    path: '/medicines',
  },
  {
    icon: 'pi pi-receipt',
    title: 'Objawy',
    path: '/symptoms',
  },
];

const route = useRoute();
</script>

<template>
  <nav>
    <ul class="flex flex-col gap-2">
      <li v-for="item in MENU" :key="item.path">
        <NuxtLink
          :to="item.path"
          class="text-bluegray-600 flex items-center space-x-2 text-sm font-semibold"
          :class="{
            'text-primary': route.path === item.path,
          }"
        >
          <PrimeButton
            class="flex w-full !justify-start gap-3 !p-2"
            :class="{
              'font-medium': route.path !== item.path,
              'font-bold': route.path == item.path,
            }"
            text
            :severity="route.path === item.path ? 'primary' : 'secondary'"
          >
            <i :class="item.icon"></i>

            <span class="text-nowrap">{{ item.title }}</span>
          </PrimeButton>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>
