<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 37" fill="none">
    <path
      d="M12.8416 36.6363C8.41116 36.0538 4.54551 34.377 1.89781 31.8705C0.4151 30.4584 0.0444228 29.717 0.397449 28.8697C0.856384 27.793 2.32144 26.9987 3.59234 27.1752C4.47491 27.2988 5.41043 27.793 7.19321 29.0639C10.0351 31.1291 11.8002 31.7116 15.5599 31.8352C19.8492 31.994 21.9674 31.3939 25.533 28.9933C28.0747 27.2811 28.4101 27.1223 29.4692 27.1223C31.1637 27.1223 32.5229 28.2343 32.5229 29.6288C32.5229 31.4115 27.9335 34.6241 23.6619 35.842C20.52 36.7422 16.1954 37.06 12.8416 36.6363Z"
      fill="url(#paint0_linear_7220_8318)"
    />
    <path
      d="M15.1363 23.3096C12.7887 22.8153 10.953 21.8975 9.382 20.4501C7.98754 19.1615 6.99906 17.6788 6.29301 15.8254V15.8254C5.96599 14.9495 5.79315 14.0235 5.78215 13.0886L5.72817 8.50011L5.68174 3.23788C5.67749 2.75595 5.81652 2.28362 6.0812 1.88086V1.88086C6.61074 1.1042 7.15793 0.821777 8.23466 0.821777C8.99367 0.821777 9.17018 0.892382 9.77033 1.51018V1.51018C10.2113 1.95113 10.46 2.54852 10.4624 3.17212L10.4764 6.92914C10.494 12.2598 10.5293 12.8953 10.9177 14.1309C11.4295 15.7725 12.5063 17.0081 14.2361 17.9083C15.0481 18.3319 15.3305 18.3849 16.9015 18.3849C18.4724 18.3849 18.7725 18.3319 19.6727 17.8906C20.9613 17.2552 22.3028 15.7901 22.8323 14.4486C23.2206 13.4954 23.2559 13.1424 23.3442 7.84701C23.4325 2.53396 23.4501 2.23388 23.8031 1.7573C24.2974 1.08655 24.8622 0.821777 25.8507 0.821777C26.4685 0.821777 26.8039 0.927686 27.1922 1.26306C28.1101 2.03972 28.1454 2.21623 28.0748 8.60602C28.0042 14.9075 27.9688 15.1547 26.9627 17.2552V17.2552C26.7611 17.6696 26.7509 18.1516 26.9349 18.5742L27.2981 19.4086C28.0395 21.1561 28.2689 22.3917 27.9159 22.8153C27.5629 23.2566 26.3802 23.0095 24.774 22.1799V22.1799C23.909 21.7363 22.884 21.7337 22.0167 22.1728L22.0027 22.1799C21.226 22.5682 20.0434 23.0095 19.3726 23.1507C18.2253 23.3978 15.9659 23.4684 15.1363 23.3096Z"
      fill="url(#paint1_linear_7220_8318)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7220_8318"
        x1="-54.148"
        y1="-10.0602"
        x2="-50.6326"
        y2="45.4152"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7220_8318"
        x1="-54.148"
        y1="-10.0602"
        x2="-50.6326"
        y2="45.4152"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03466F" />
        <stop offset="0.495" stop-color="#1F798D" />
        <stop offset="0.815" stop-color="#46B6B0" />
        <stop offset="1" stop-color="#71E59D" />
      </linearGradient>
    </defs>
  </svg>
</template>
