<script setup lang="ts">
import type { Notification } from '~/utils/types';

const op = ref();

defineProps<{
  notification: Notification;
}>();

defineEmits(['read', 'delete']);
</script>

<template>
  <article
    class="flex items-start gap-3 rounded-md px-2 py-3"
    :class="{
      'bg-primary-100': !notification.is_read,
    }"
  >
    <div
      class="bg-warning-400 flex aspect-square size-8 shrink-0 items-center justify-center rounded-full"
    >
      <i class="pi pi-exclamation-triangle text-[16px] text-white" />
    </div>

    <NuxtLink
      :to="notification.url"
      class="flex-1 space-y-1 text-gray-iron-900"
      :class="{
        'cursor-pointer hover:underline': true,
      }"
    >
      <div class="text-sm">{{ notification.title }}</div>

      <div class="text-xs text-gray-iron-500">
        {{ notification.created_at }}
      </div>
    </NuxtLink>

    <PrimeButton
      text
      severity="primary"
      rounded
      class="aspect-square size-10 bg-white"
      @click="(event: MouseEvent) => op.toggle(event)"
    >
      <i class="pi pi-ellipsis-v text-base text-gray-iron-700" />
    </PrimeButton>

    <PrimeOverlayPanel ref="op" class="notification-panel">
      <div class="flex flex-col gap-0">
        <PrimeButton
          class="w-full justify-start px-2"
          text
          severity="secondary"
          @click.stop="$emit('read'), op.toggle()"
        >
          Oznacz jako przeczytane
        </PrimeButton>

        <PrimeButton
          class="w-full justify-start px-2"
          text
          severity="secondary"
          @click.stop="$emit('delete'), op.toggle()"
        >
          Usuń z listy
        </PrimeButton>
      </div>
    </PrimeOverlayPanel>
  </article>
</template>

<style>
/* hide popover arrow */
.p-popover.notification-panel:after,
.p-popover.notification-panel:before {
  display: none;
}
</style>
