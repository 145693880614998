<script setup lang="ts"></script>

<template>
  <div class="bg-surface-section flex min-h-screen">
    <SideMenu class="" />
    <main
      class="bg-gray-iron-100 min-h-screen flex-1 space-y-9 p-5 md:px-8 md:pt-8"
    >
      <HeaderBar />

      <slot></slot>
    </main>
  </div>
</template>
